import { GpsFixed } from "@material-ui/icons";
import React, { Component } from "react";
import styled from "styled-components";
import Chart from "react-google-charts";
import Wrapper from "../components/customdatepicker";
import devices from "../components/navbar/devices.json";
import SensorCol from "./SensorCol.json";
import moment from "moment";
import "./style.css";

const LoadingDiv = styled.div`
  position: fixed;
  top: 10%;
  right: 45%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const DateInput = styled.div`
  position: absolute;
  top: 7px;
  right: 10px;
`;
const Logo = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 25px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
`;
const SelectDeviceDiv = styled.div`
  background: white;
  position: absolute;
  top: 15px;
  right: 545px;
  left: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const SelectDevice = styled.select`
  margin-right: 15px;
  padding: 10px 15px 10px 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
`;
const SelectDeviceOption = styled.option`
  width: 200px;
  padding: 10px 10px 10px 10px;
  font-size: 16px;
`;
const SelectSensorDiv = styled.div`
  background: white;
  position: absolute;
  top: 15px;
  right: 425px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const SelectSensor = styled.select`
  margin-right: 15px;
  padding: 10px 15px 10px 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
`;
const SelectSensorOption = styled.option`
  width: 200px;
  padding: 10px 10px 10px 10px;
  font-size: 16px;
`;

const SelectSensorColDiv = styled.div`
  background: white;
  position: absolute;
  top: 15px;
  right: 250px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const SelectSensorCol = styled.select`
  margin-right: 15px;
  padding: 10px 15px 10px 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
`;
const SelectSensorColOption = styled.option`
  width: 200px;
  padding: 10px 10px 10px 10px;
  font-size: 16px;
`;

const LogoIITD = styled.img`
position: absolute;
top: 65px;
left: 10px;
border-radius:25px;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
`
const LogoDIMTS = styled.img`
position: absolute;
top: 120px;
left: 10px;
border-radius:25px;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
`

const LogoLabelIITD = styled.label`
padding:5px 10px 10px 10px;
position: absolute;
background:white;
top: 70px;
left: 70px;
font-weight:bold;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
border-radius:5px;
`
const LogoLabelDIMTS = styled.label`
padding:5px 10px 10px 10px;
position: absolute;
background:white;
top: 125px;
left: 70px;
font-weight:bold;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
border-radius:5px;
`

const LogoLabel = styled.label`
  padding: 5px 10px 10px 10px;
  position: absolute;
  background: white;
  top: 15px;
  left: 70px;
  font-weight: bold;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`;

const ChartDiv = styled.div`
  position: fixed;
  margin-top: 170px;
  max-width: 100%;
`;

//const BASE_URL = `http://localhost:5000/eziomotiv-255716/us-central1/app/chart`;

const BASE_URL = `https://us-central1-eziomotiv-255716.cloudfunctions.net/app/chart`;

class ChartComponent extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let currentDateTime = moment(
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes()
      )
    ); //.subtract(1,'days');
    let start = moment(currentDateTime).subtract(10, "minutes");
    let end = moment(currentDateTime);
    this.state = {
      timeInterval: 50000,
      data: [], //Nodes,
      start: start.format("YYYY-MM-DD HH:mm:SS"),
      end: end.format("YYYY-MM-DD HH:mm:SS"),
      deviceId: "00000000d5ddcf9f",
      sensor: "pol",
      sensor_col: "pm2_5",
      url: `${BASE_URL}/pol/deviceId`,
      loading: false,
      units: {
        pm2_5: "\u03BCg/m3",
        pm1_0: "\u03BCg/m3",
        pm10: "\u03BCg/m3",
        pressure: "hPa",
        lat: "\u00b0",
        lng: "\u00b0",
        humidity: "RH(%)",
        temperature: "\u00b0C",
        ax: "m/s2",
        ay: "m/s2",
        az: "m/s2",
        gx: "rad/s2",
        gy: "rad/s2",
        gz: "rad/s2",
      },
      unit: "\u03BCg/m3",
      SensorColOptions: SensorCol[0].map((sensor) => {
        return sensor.map((sensorCol) => {
          return (
            <SelectSensorColOption style key={sensorCol} value={sensorCol}>
              {sensorCol}
            </SelectSensorColOption>
          );
        });
      }),
    };
  }

  handleDataFetching = async () => {
    let url =
      this.state.url +
      `?start=${this.state.start}&end=${this.state.end}&deviceId=${this.state.deviceId}&sensor=${this.state.sensor}&sensor_col=${this.state.sensor_col}`;
    this.setState({
      loading: true,
    });
    try {
      let resp = await fetch(`${url}`);
      let status = resp.status;
      resp = await resp.json();
      let data = resp.data;

      if (status === 200) {
        this.setState({
          loading: false,
        });
        this.setState({ data });
        return;
      }
    } catch (e) {}
  };

  componentWillMount() {
    this.handleDataFetching();
  }

  handleDeviceChange = (e) => {
    this.setState(
      {
        deviceId: e.target.value,
      },
      () => {
        this.handleDataFetching();
      }
    );
  };

  handleSensorChange = (e) => {
    let selectedSensor = e.target.value;
    let sensorIndex = 0;
    let selectedSensorCol = "pm2_5";
    let units = this.state.units;
    selectedSensor === "pol"
      ? (sensorIndex = 0)
      : selectedSensor === "bme"
      ? (sensorIndex = 1)
      : selectedSensor === "gps"
      ? (sensorIndex = 2)
      : selectedSensor === "mpu"
      ? (sensorIndex = 3)
      : (sensorIndex = 0);

    selectedSensor === "pol"
      ? (selectedSensorCol = "pm2_5")
      : selectedSensor === "bme"
      ? (selectedSensorCol = "pressure")
      : selectedSensor === "gps"
      ? (selectedSensorCol = "lat")
      : selectedSensor === "mpu"
      ? (selectedSensorCol = "ax")
      : (selectedSensorCol = "pm2_5");
    this.setState(
      {
        SensorColOptions: SensorCol[sensorIndex].map((sensor) => {
          return sensor.map((sensorCol) => {
            return (
              <SelectSensorColOption style key={sensorCol} value={sensorCol}>
                {sensorCol}
              </SelectSensorColOption>
            );
          });
        }),
        sensor: e.target.value,
        sensor_col: selectedSensorCol,
        unit: units[selectedSensorCol],
      },
      () => {
        this.handleDataFetching();
      }
    );
  };

  handleSensorColChange = (e) => {
    let units = this.state.units;
    this.setState(
      {
        sensor_col: e.target.value,
        unit: units[e.target.value],
      },
      () => {
        this.handleDataFetching();
      }
    );
  };

  handleStartAndEndSubmit = async (start, end) => {
    try {
      let startDateTime = moment(start).utc().format("YYYY-MM-DD HH:mm:ss");
      let endDateTime = moment(end).utc().format("YYYY-MM-DD HH:mm:ss");
      this.setState(
        {
          start: startDateTime,
          end: endDateTime,
        },
        () => {
          this.handleDataFetching();
        }
      );
    } catch (e) {}
  };

  options = devices.map((device) => {
    return (
      <SelectDeviceOption style key={device.id} value={device.deviceId}>
        {device.id}, Route: {device.route_no}
      </SelectDeviceOption>
    );
  });

  render() {
    let updatedData = this.state.data;

    let tempChartData = updatedData.map((val) => {
      return [
        new Date(Date.parse(val.dateTime + "+00:00")),// changing from UTC to IST
        val[this.state.sensor_col],
      ];
    });

    let chartData = tempChartData.map((val) => {
      return [
        moment(val[0]).format("MMM DD HH:mm:SS"),
        val[1],
      ];
    });
    return (
      <>
        <Logo src="/logo.png" height="45" width="45" />
        <LogoIITD src="/iitd_logo.png" height="45" width="45" />
        <LogoDIMTS src="/dimts_logo.png" height="45" width="45" />
        <LogoLabel>Aerogram</LogoLabel>
        <LogoLabelIITD>IIT, Delhi</LogoLabelIITD>
        <LogoLabelDIMTS>DIMTS</LogoLabelDIMTS>
        <DateInput id="dateRangeSelectorDiv">
          <a href="#" style={{ color: "black" }}>
            <Wrapper handleStartAndEndSubmit={this.handleStartAndEndSubmit} />
          </a>
        </DateInput>

        <SelectDeviceDiv id="deviceSelectorDiv">
          <SelectDevice onChange={this.handleDeviceChange}>
            {this.options}
          </SelectDevice>
        </SelectDeviceDiv>
        <SelectSensorDiv id="sensorSelectorDiv">
          <SelectSensor onChange={this.handleSensorChange}>
            <SelectSensorOption value="pol">POL</SelectSensorOption>
            <SelectSensorOption value="bme">BME</SelectSensorOption>
            <SelectSensorOption value="gps">GPS</SelectSensorOption>
            <SelectSensorOption value="mpu">MPU</SelectSensorOption>
          </SelectSensor>
        </SelectSensorDiv>
        <SelectSensorColDiv id="sensorColSelectorDiv">
          <SelectSensorCol onChange={this.handleSensorColChange}>
            {this.state.SensorColOptions}
          </SelectSensorCol>
        </SelectSensorColDiv>
        <ChartDiv id="chartDiv">
          {this.state.loading ? (
            <LoadingDiv>
              <div className="loader"></div>
            </LoadingDiv>
          ) : chartData.length === 0 ? (
            <Chart
              width={"100vw"}
              height={"75vh"}
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={[
                ["no data", "no data"],
                [0, 0],
              ]}
              options={{
                hAxis: {
                  title: "Time",
                },
                vAxis: {
                  title: "no data",
                },
              }}
              rootProps={{ "data-testid": "1" }}
            />
          ) : (
            <Chart
              width={"100vw"}
              height={"75vh"}
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={[
                [{ type: "string", label: "Time" }, this.state.sensor_col],
                ...chartData,
              ]}
              options={{
                hAxis: {
                  title: "TIME",
                },
                vAxis: {
                  title: this.state.sensor_col + " (" + this.state.unit + ")",
                },
              }}
              rootProps={{ "data-testid": "1" }}
            />
          )}
        </ChartDiv>
      </>
    );
  }
}

export default ChartComponent;
