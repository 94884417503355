import React from "react";
import { InfoWindow as MapInfoWindow } from "react-google-maps";

const InfoWindow = props => {
  const { selectedMarker, setSelectedMarker } = props;
  if (!selectedMarker) return null;
  return (
    <MapInfoWindow
      onCloseClick={() => {
        setSelectedMarker(null);
      }}
      position={{
        lat: parseFloat(selectedMarker.lat),
        lng: parseFloat(selectedMarker.lng)
      }}
      options={{
        alignBottom: true,
        pane: "mapPane",
        pixelOffset: new window.google.maps.Size(0, -50)
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <pre style={{ fontWeight: "bold", margin: 0 }}>{selectedMarker.header}</pre>
        {selectedMarker.info.map((info, index) => {
          return <p key={index} style={{ margin: 0 }}>{`${info}`}</p>;
        })}
      </div>
    </MapInfoWindow>
  );
};
export default InfoWindow;
