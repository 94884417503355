import React, { useState } from "react";
import Navbar from "../../components/navbar";
import { withGoogleMap, withScriptjs } from "react-google-maps";
import { GoogleMap } from "react-google-maps";
import {
  mapDefaultOptions,
  mapWrapperStylings,
  mapWrapperContainerStyle,
  MapUrl
} from "./mapSettings";
import { withFirebase } from "../../components/Firebase";
import Legend from "../../components/Legend";
//import HeatMap from "../../components/HeatMap";
//import { Nodes } from "../../constants/readers";
import { OpenAQINodes } from "../../constants/open_aqi_readers";
import OpenAQIMarkerView from "../../components/OpenAQIMarker";
import InfoWindow from "../../components/InfoWindow";
//import {  SettingsModal,  SettingsButton,  ZoomOutButton} from "../../components/SettingsModal";
//import Polyline from "../../components/PolyLine";
import MarkerView from "../../components/Marker";
import {
  IIT_D_LL,
  POLYLINE_MAP_VIEW,
  HEAT_MAP_VIEW,
  MARKERS_MAP_VIEW,
  ClusterIcon,
  PM_DETAILS,
  tempData
} from "../../utils/utils";
import moment from "moment";
import { toast } from "react-toastify";
import EmptyDataNotification from "../../components/EmptyDataNotification";
import "./style.css";
import styled from 'styled-components';


const LoadingDiv = styled.div`
 position: absolute;
top: 35%;
right: 45%;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
border-radius:5px;
  `

const MapWrapped = withScriptjs(withGoogleMap(Map));

// For Deployment in Development
// const BASE_URL = `https://us-central1-eziomotiv-255716.cloudfunctions.net/app/dev`;

// For Localhost in Development
// const BASE_URL = `http://localhost:5000/eziomotiv-255716/us-central1/app/dev`;

// For Deployment in Production
  const BASE_URL = `https://us-central1-eziomotiv-255716.cloudfunctions.net/app`;

// For Localhost in Production
// const BASE_URL = `http://localhost:5000/eziomotiv-255716/us-central1/app`;

class MapHome extends React.Component {
  constructor(props) {
    super(props);
    this.unsubscribe = null;
    this.timer = null;
    this.state = {
      timeInterval: 50000,
      data: {}, //Nodes,
      start: null,
      end: null,
      deviceId:"00000000d5ddcf9f",
      aqiData: OpenAQINodes,
      url: `${BASE_URL}/pol/00000000d5ddcf9f`,
      loading: false
    };
  }

  handleDataFetching = async () => {
    
    let url = this.state.url;
    this.setState({
      loading:true
    })
    try {
      let resp = await fetch(`${url}`);
      let status = resp.status;
      resp = await resp.json();
      let data = resp.data;
      if (status === 200) {
        this.setState({
          loading:false
        })
        this.setState({ data });
        return;
      }
    } catch (e) {}
  };

  componentDidMount() {
    this.handleDataFetching();
    //this.timer = setInterval(this.handleDataFetching, this.state.timeInterval);
  }

  componentWillUnmount = () => {
    //clearInterval(this.timer);
    //this.timer = null;
  };

  handleDeviceChange = async (deviceId) => {
    try {
      let newDeviceId = deviceId;
      if (this.state.start == null)
      {
        this.setState(
          {
            deviceId: newDeviceId,
            url: `${BASE_URL}/pol/${newDeviceId}`
          },
          () => {
            this.handleDataFetching();
          }
        );
      }
      else {
        this.setState(
          {
            deviceId: newDeviceId,
            url: `${BASE_URL}/pol/${newDeviceId}?start=${this.state.start}&end=${this.state.end}`
          },
          () => {
            this.handleDataFetching();
          }
        );
      }
      
    } catch (e) { }
  };

  handleStartAndEndSubmit = async (start, end) => {
    try {
      let startDateTime = moment(start)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      let endDateTime = moment(end)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    
        this.setState(
          {
            start: startDateTime,
            end: endDateTime,
            url: `${BASE_URL}/pol/${this.state.deviceId}?start=${startDateTime}&end=${endDateTime}`
          },
          () => {
            this.handleDataFetching();
          }
        );
    
     
    } catch (e) {}
  };
  render = () => {
    return (
      <div style={mapWrapperStylings}>
        <MapWrapped
          googleMapURL={MapUrl}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={mapWrapperContainerStyle} />}
          mapElement={<div style={{ height: `100%` }} />}
          data={this.state.data}
          handleDeviceChange={this.handleDeviceChange}
          handleStartAndEndSubmit={this.handleStartAndEndSubmit}
          startDate={this.state.start}
          endDate={this.state.end}
          aqiData={this.state.aqiData}
          firebase={this.props.firebase}
          loading={this.state.loading}
          data={this.state.data}
        />
      </div>
    );
  };
}

function Map(props) {
  let data = props.data || [];
  const [zoom, setZoom] = useState(16);
  let latitude = getLatitude(data);
  let longitude = getLongitude(data);
  const [lat, setLat] = useState(latitude);
  const [lng, setLng] = useState(longitude);
  React.useEffect(() => {
    setLat(latitude);
    setLng(longitude);
  }, [latitude, longitude]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showLabel, setShowLabel] = useState(true);
  //const [selectedView, setSelectedView] = useState(MARKERS_MAP_VIEW);
  const handleDateTimeSubmit = (start, end) => {
    props.handleStartAndEndSubmit(start, end);
    setShowModal(false);
  };
  
  return (<>
    <Navbar {...props} />
    <div>{ props.loading?<LoadingDiv><div className="loader"></div></LoadingDiv>:<>
      
      <GoogleMap
        zoom={zoom}
        center={{ lat, lng }}
        //defaultCenter={{ lat: IIT_D_LL[0], lng: IIT_D_LL[1] }}
        defaultOptions={mapDefaultOptions}
      >
        {/*selectedView === HEAT_MAP_VIEW && <HeatMap data={data} />*/}
        {/*selectedView === POLYLINE_MAP_VIEW && <Polyline data={data} />*/}
        {/*selectedView === MARKERS_MAP_VIEW && <Markers data={data} />*/}

        {renderMarker({ ...props, setSelectedMarker, showLabel })}
        {renderAQIMarkers({ ...props, setSelectedMarker, showLabel })}
      </GoogleMap>
      {/*selectedView !== HEAT_MAP_VIEW && <Legend />*/}
      <Legend/>
      {!data.length && <EmptyDataNotification />}
      {/*<SettingsButton showModal={() => setShowModal(true)} />*/}
      {/*renderModal({
        showModal,
        setShowModal,
        showLabel,
        setShowLabel,
        selectedView,
        setSelectedView,
        handleDateTimeSubmit,
        startDate: props.startDate,
        endDate: props.endDate
      })*/}
      </>}{
        <InfoWindow
          selectedMarker={selectedMarker}
          setSelectedMarker={setSelectedMarker}
        />
      }
    </div></>
  );
}

function renderMarker(props) {
  if (!props.data.length) return null;
  return props.data.map((marker, index) => {
    return (
      <MarkerView
        key={index}
        marker={marker}
        firebase={props.firebase}
        index={index}
        showLabel={props.showLabel}
        data={props.data}
        onClick={markerInfo => {
          props.setSelectedMarker(markerInfo);
        }}
      />
    );
  });
}

function renderAQIMarkers(props) {
  if (!props.aqiData.length) return null;
  return props.aqiData.map((marker, index) => {
    return (
      <OpenAQIMarkerView
        key={index}
        marker={marker}
        index={index}
        showLabel={props.showLabel}
        onClick={markerInfo => {
          props.setSelectedMarker(markerInfo);
        }}
      />
    );
  });
}

/*function renderModal(props) {
  const {
    showModal,
    setShowModal,
    setShowLabel,
    showLabel,
    setSelectedView,
    selectedView,
    handleDateTimeSubmit,
    startDate,
    endDate
  } = props;
  if (!showModal) return null;
  const handleShowLabel = () => {
    setShowLabel(!showLabel);
  };
  const handleChange = (event, viewValue) => {
    setSelectedView(viewValue);
  };
  return (
    <SettingsModal
      closeModal={() => setShowModal(false)}
      handleShowLabel={handleShowLabel}
      showLabel={showLabel}
      selectedView={selectedView}
      handleViewSelection={handleChange}
      handleDateTimeSubmit={handleDateTimeSubmit}
      startDate={startDate}
      endDate={endDate}
    />
  );
}

function Markers({ data }) {
  if (!data.length) return null;
  return data.map((marker, index) => {
    return <MarkerView key={index} marker={marker} index={index} />;
  });
}*/

const getLatitude = data => {
  if (data.length) {
    return parseFloat(data[0].lat);
  }
  return IIT_D_LL[0];
};
const getLongitude = data => {
  if (data.length) {
    return parseFloat(data[0].lng);
  }
  return IIT_D_LL[1];
};

export default withFirebase(MapHome);
