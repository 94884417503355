import React from "react";
import "./legend.css";
import { PM_DETAILS } from "../../utils/utils";

export default class Legend extends React.Component {
  render = () => {
    return (
      <div
        className=" legend container "
        style={{
          position: "absolute",
          bottom: "20px",
          left: "20px"
        }}
      >
        {PM_DETAILS.map((pmDetail, index) => {
          return (
            <div className="legend-item" key={index}>
              <i style={{ background: pmDetail.c }} />
              {`${pmDetail.r[0]} - ${pmDetail.r[1]}`}
            </div>
          );
        })}
      </div>
    );
  };
}
