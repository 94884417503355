import React from "react";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl } from "react-bootstrap";
import moment from "moment";
import "./style.css";
//import $ from "jquery";

class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let currentDateTime = moment(
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes()
      )
    ); //.subtract(1,'days');
    let start = moment(currentDateTime).subtract(10, "minutes");
    let end = moment(currentDateTime); //.add(1, "days").subtract(0, "seconds");
    this.state = {
      start: start,
      end: end,
      placehonder:
        start.format("MMM DD, YYYY  HH:mm") +
        " - " +
        end.format("MMM DD, YYYY  HH:mm"),
      selectedRange: "",
      startDate: start.format("DD"),
      startMonthYear: start.format("MMM'YY"),
      startTime: start.format("HH:mm"),
      endDate: end.format("DD"),
      endMonthYear: end.format("MMM'YY"),
      endTime: end.format("HH:mm")
    };

    this.applyCallback = this.applyCallback.bind(this);
  }

  componentDidMount() {
    const dateTimeFeild = document.querySelector("#DateTimeFeild");
    dateTimeFeild.addEventListener("click", () => {
      document
        .querySelector("#DateTimeInput_end")
        .setAttribute("readonly", true);
      document
        .querySelector("#DateTimeInput_start")
        .setAttribute("readonly", true);
    });

    document.querySelectorAll("option").forEach(el => {
      el.addEventListener("mousedown", e => {
        e.preventDefault();
      });
    });
  }

  applyCallback(startDate, endDate) {
    this.setState({
      start: startDate,
      end: endDate,
      startDate: startDate.format("DD"),
      startMonthYear: startDate.format("MMM'YY"),
      startTime: startDate.format("HH:mm"),
      endDate: endDate.format("DD"),
      endMonthYear: endDate.format("MMM'YY"),
      endTime: endDate.format("HH:mm"),
      selectedRange:
        startDate.format("MMM DD, YYYY  HH:mm") +
        " - " +
        endDate.format("MMM DD, YYYY  HH:mm")
    });
    //this.props.timeF(startDate.format('YYYY-MM-DD HH:mm:SS'), endDate.format('YYYY-MM-DD HH:mm:SS'));
    this.props.handleStartAndEndSubmit(startDate, endDate);
  }

  render() {
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start)
      .add(1, "days")
      .subtract(1, "seconds");
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days")
      ],
      "3 Days": [moment(start).subtract(3, "days"), moment(end)]
    };
    let local = {
      format: "DD-MM-YYYY HH:mm:ss",
      sundayFirst: false
    };
    let maxDate = moment(start).add(24, "hour");

    return (
      <div>
        <DateTimeRangeContainer
          ranges={ranges}
          start={this.state.start}
          end={this.state.end}
          local={local}
          maxDate={maxDate}
          applyCallback={this.applyCallback}
          leftMode={true}
          noMobileMode={true}
          darkMode={false}
          style={{}}
        >
          <div
            id="DateTimeFeild"
            style={{
              float: "right",
              background: "white",
              padding: "0px 20px 10px 20px",
              borderRadius: "10px",
              boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)"
            }}
          >
            <div style={{ float: "right", marginLeft: "30px" }}>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  lineHeight: "40px"
                }}
              >
                TILL
              </span>
              <br />
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  lineHeight: "0px"
                }}
              >
                {this.state.endDate}{" "}
              </span>
              <span style={{ lineHeight: "0px" }}>
                {this.state.endMonthYear}
              </span>
              <br />
              <span style={{ fontSize: "14px" }}>{this.state.endTime}</span>
            </div>

            <div style={{ float: "right" }}>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  lineHeight: "40px"
                }}
              >
                FROM
              </span>
              <br />
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  lineHeight: "0px"
                }}
              >
                {this.state.startDate}{" "}
              </span>
              <span style={{ lineHeight: "0px" }}>
                {this.state.startMonthYear}
              </span>
              <br />
              <span style={{ fontSize: "14px" }}>{this.state.startTime}</span>
            </div>
          </div>
        </DateTimeRangeContainer>
      </div>
    );
  }
}

export default Wrapper;

/*
                    <FormControl
                        id="formControlsTextB"
                        type="text"
                        label="Text"
                        value={this.state.selectedRange}
                        placeholder={this.state.placehonder}
                        style={{ width: '257px', outline: 'none', border: '3px black solid', borderRadius: '10px', paddingLeft: '10px', paddingRight: '10px', color: '#28abe1' }}
                        autoComplete='off'
                    />*/
