import React, { Component } from 'react';
import styled from 'styled-components';
import Wrapper from '../customdatepicker';
import devices from './devices.json';
import "./style.css";

const SelectDeviceDiv = styled.div`
background:white;
 position: absolute;
top: 120px;
right: 10px;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
border-radius:5px;
  `

const SelectDevice = styled.select`
margin-right:15px;
padding:10px 15px 10px 15px;
outline:none;
border:none;
border-radius:5px;
font-weight:bold;
font-size:15px;
`
const SelectDeviceOption = styled.option`
width:200px;
padding:10px 10px 10px 10px;
font-size:16px;
`

const Logo = styled.img`
position: absolute;
top: 10px;
left: 10px;
border-radius:25px;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
`

const LogoIITD = styled.img`
position: absolute;
top: 70px;
left: 10px;
border-radius:25px;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
`

const LogoDIMTS= styled.img`
position: absolute;
top: 130px;
left: 10px;
border-radius:25px;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
`

const LogoLabel = styled.label`
padding:5px 10px 10px 10px;
position: absolute;
background:white;
top: 15px;
left: 70px;
font-weight:bold;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
border-radius:5px;
`
const LogoLabelIITD = styled.label`
padding:5px 10px 10px 10px;
position: absolute;
background:white;
top: 75px;
left: 70px;
font-weight:bold;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
border-radius:5px;
`
const LogoLabelDIMTS = styled.label`
padding:5px 10px 10px 10px;
position: absolute;
background:white;
top: 135px;
left: 70px;
font-weight:bold;
box-shadow:0 0 15px rgba(0, 0, 0, 0.2);
border-radius:5px;
`

const DateInput = styled.div`
position: absolute;
top: 7px;
right: 10px;
`


class NavBar extends Component {
  constructor(props) {
    super(props) 
    this.state = {}
  }

  handleDeviceChange = (e) => {
    this.props.handleDeviceChange(e.target.value);
  }
  
  options = devices.map((device) => {
    return(
      <SelectDeviceOption style key={device.id} value={device.deviceId}>{device.id},  Route: {device.route_no}</SelectDeviceOption>
    )
  })

    render() { 
        return (<>
            <Logo src="/logo.png" height="45" width="45" />
          <LogoIITD src="/iitd_logo.png" height="45" width="45" />
          <LogoDIMTS src="/dimts_logo.png" height="45" width="45" />
          <LogoLabel>Aerogram</LogoLabel>
          <LogoLabelIITD>IIT, Delhi</LogoLabelIITD>
          <LogoLabelDIMTS>DIMTS</LogoLabelDIMTS>
          <DateInput id="dateRangeSelectorDiv"><a href="#" style={{ color: 'black' }}><Wrapper {...this.props} /></a></DateInput>
          <SelectDeviceDiv id="deviceSelectorDiv"><SelectDevice onChange={this.handleDeviceChange}>
            
            {this.options}
          </SelectDevice></SelectDeviceDiv>
          </>
        );
    }
}
 
export default NavBar;
