import ReactDOMServer from "react-dom/server";
import React from "react";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { getMarkerDetails, getDateDiffInDays } from "../../utils/utils";

export default class OpenAQIMarkerView extends React.Component {
  constructor(props) {
    super(props);
    this.unsubscribe = null;
    this.timer = null;
    this.state = {
      pmLevel: 0,
      lastUpdated: "",
      timeInterval: 15 * 60 * 1000
    };
  }

  handleDataFetching = async () => {
    let { marker } = this.props;

    // For Deployment in Development
    // let url =`https://us-central1-eziomotiv-255716.cloudfunctions.net/app/dev`

    // For Localhost in Development
    // let url = `http://localhost:5000/eziomotiv-255716/us-central1/app/dev`;

    // For Deployment in Production
     let url =`https://us-central1-eziomotiv-255716.cloudfunctions.net/app`

    // For Localhost in Production
    // let url = `http://localhost:5000/eziomotiv-255716/us-central1/app`

    try {
      fetch(`${url}/aqi/${marker.uid}`)
        .then(response => response.json())
        .then(respData => {
          try {
            let data = respData.data;
            if (data.status && data.status === "ok") {
              let pm25 = data.data.iaqi.pm25.v;
              let time = data.data.time.s;
              let dayDiff = getDateDiffInDays(time.split(" ")[0]);
              if (dayDiff) {
                this.setState({ pmLevel: pm25, lastUpdated: time });
              }
            }
          } catch (e) {}
        });
    } catch (e) {}
  };

  componentDidMount() {
    const { marker, showLabel } = this.props;
    this.handleDataFetching();
    this.timer = setInterval(this.handleDataFetching, this.state.timeInterval);
  }

  componentWillUnmount = () => {
    clearInterval(this.timer);
    this.timer = null;
  };

  render = () => {
    const { marker, showLabel } = this.props;
    const { pmLevel, lastUpdated } = this.state;
    if (pmLevel === "0" || pmLevel <= 0) return null;
    return (
      <MarkerWithLabel
        title={`${pmLevel}_${marker.loc_name}_${lastUpdated}`}
        options={{ pmLevel }}
        position={{
          lat: marker.lat,
          lng: marker.lng
        }}
        icon={{ url: Icon(pmLevel) }}
        labelAnchor={getLabelAnchor(pmLevel)}
        labelStyle={{ fontSize: "14px", zIndex: "0" }}
        onClick={() => {
          this.props.onClick(getMarkerPopupInfo(marker, pmLevel, lastUpdated));
        }}
      >
        {getLabel(showLabel, pmLevel)}
      </MarkerWithLabel>
    );
  };
}

const getLabel = (showLabel, pmLevel) => {
  if (showLabel) {
    return <div>{`${pmLevel}`}</div>;
  }
  return <div />;
};

const getMarkerPopupInfo = (marker, pmLevel, lastUpdated) => {
  let obj = {};
  obj.header = marker.loc_name;
  obj.lat = marker.lat;
  obj.lng = marker.lng;
  let info = [];
  info.push(`PM_2.5 Level : ${pmLevel} μg/m³`);
  info.push(`Last Updated : ${lastUpdated}`);
  info.push(`Courtesy : waqi.info`);
  obj.info = info;
  return obj;
};

const getLabelAnchor = pmLevel => {
  let xVal = 5;
  pmLevel = typeof pmLevel === "number" ? pmLevel + "" : pmLevel;
  let charCount = pmLevel.length;
  if (charCount > 1 && charCount < 3) {
    xVal = 8;
  }
  if (charCount > 2) {
    xVal = 14;
  }
  return new window.google.maps.Point(xVal, 34);
};

const Icon = (pmLevel = 0) => {
  let { w, h, ac } = getMarkerDetails(pmLevel);
  w = 35;
  h = 35;
  let svgView = (
    <svg
      height={`${h}pt`}
      viewBox="0 0 512 512"
      width={`${w}pt`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m512 256c0 141.386719-114.613281 256-256 256s-256-114.613281-256-256 114.613281-256 256-256 256 114.613281 256 256zm0 0"
        fill={ac}
      />
    </svg>
  );
  return (
    "data:image/svg+xml," + escape(ReactDOMServer.renderToStaticMarkup(svgView))
  );
};
