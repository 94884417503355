// import { getMarkerDetails } from "../../utils/utils";
import ReactDOMServer from "react-dom/server";
import React from "react";
// import { Marker } from "react-google-maps";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import {
  getMarkerDetails,
  LAT_KEY,
  LNG_KEY,
  PM_2_5_KEY
} from "../../utils/utils";
import moment from "moment";

export default class MarkerView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pmLevel: 0,
      
      lastUpdated: "",
      timeInterval: 50000
    };
  }
  showLocation= "";

  readLocation = (marker, pmLevel, lastUpdated) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${marker.lat},${marker.lng}&key=AIzaSyA-69-qDYHawzwlSFXqYNmE4JYRfdDbhQ8`;

    try {
      fetch(`${url}`)
        .then(response => response.json())
        .then(respData => {
          try {
            //this.setState({
              
           // });
            this.showLocation= respData.results[0].formatted_address;
            this.props.onClick(
              getMarkerPopupInfo(
                marker,
                pmLevel,
                lastUpdated,
                this.showLocation.replaceAll(", ", "\n")
              )
            );
          } catch (e) {}
        });
    } catch (e) {}
  };

  getPropsVal = () => {
    try {
      return {
        pmLevel: this.props.marker[PM_2_5_KEY],
        lat: parseFloat(this.props.marker[LAT_KEY]) || 0,
        lng: parseFloat(this.props.marker[LNG_KEY]) || 0
      };
    } catch (e) {
      return {
        pmLevel: 0,
        lat: 0,
        lng: 0
      };
    }
  };
  render = () => {
    const { marker, showLabel } = this.props;
    let lastUpdated = marker.dateTime;
    lastUpdated=new Date(Date.parse(lastUpdated + "+00:00"))
    lastUpdated=moment(lastUpdated).format("YYYY-MM-DD HH:mm:SS");
    let { pmLevel, lat, lng } = this.getPropsVal();
    //this.readLocation(lat, lng);
    if (pmLevel === "0" || pmLevel <= 0) return null;
    return (
      <MarkerWithLabel
        options={{ pmLevel }}
        title={`${pmLevel}  μg/m³`}
        position={{
          lat,
          lng
        }}
        icon={{ url: Icon(pmLevel,this.props.data,lat,lng) }}
        labelAnchor={getLabelAnchor(pmLevel)}
        labelStyle={{ fontSize: "14px", zIndex: "0" }}
        onClick={async e => {
          this.readLocation(marker, pmLevel, lastUpdated);
        }}
      >
        {getLabel(false, pmLevel)}
      </MarkerWithLabel>
    );
  };
}

const getLabel = (showLabel, pmLevel) => {
  if (showLabel) {
    return <div>{`${pmLevel}`}</div>;
  }
  return <div />;
};

const getMarkerPopupInfo = (marker, pmLevel, lastUpdated, loc_name) => {
  let obj = {};
  obj.header = loc_name;
  obj.lat = marker.lat;
  obj.lng = marker.lng;
  let info = [];
  info.push(`PM 2.5: ${pmLevel} μg/m³`);
  info.push(`Last Updated : ${lastUpdated}`);
  info.push(`Courtesy : aerogram.in`);
  obj.info = info;
  return obj;
};

const getLabelAnchor = (pmLevel = 0) => {
  let xVal = 5;
  pmLevel = typeof pmLevel === "number" ? pmLevel + "" : pmLevel;
  let charCount = pmLevel.length;
  if (charCount > 1 && charCount < 3) {
    xVal = 8;
  }
  if (charCount > 2) {
    xVal = 14;
  }
  return new window.google.maps.Point(xVal, 34);
};

const toRad=(val)=>{
    return val * (Math.PI/180)
}

const getDegrees=(oLat,oLng,nLat,nLng)=>{ 
    let deg=0;
    if(oLat==nLat && oLng==nLng){
        return deg=0;
    }
    else{
    let deg=Math.atan((nLng - oLng) / (nLat - oLat)) * 180/Math.PI;
    if(deg==0){
    return deg;
    }
    else{
        return -deg;
    }
}
}

const Icon = (pmLevel = 0,data,lat,lng) => {
  let { w, h, ac } = getMarkerDetails(pmLevel);
  w = 15;
  h = 15;
 

  let svgView=null;


if(data[0].lat==lat && data[0].lng==lng){
    let angle=getDegrees(data[1].lat,data[1].lng,data[0].lat,data[0].lng)
    let rotate="rotate("+angle.toString()+" 13 13)";
 
    
    svgView=(
        <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13" cy="13" r="12"
        stroke={ac} strokeWidth="3" fill={ac} />
        <line x1="5" y1="13" x2="20" y2="13" transform= {rotate} style={{stroke:"rgb(0,0,0)",strokeWidth:"2" }}/>
         <line x1="20" y1="13" x2="13" y2="5" transform= {rotate} style={{stroke:"rgb(0,0,0)",strokeWidth:"2" }}/>
         <line x1="20" y1="13" x2="13" y2="20" transform= {rotate} style={{stroke:"rgb(0,0,0)",strokeWidth:"2" }} />
      </svg>
      );
}
else{
    svgView=(
        <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13" cy="13" r="12"
        stroke={ac} strokeWidth="3" fill={ac} opacity="0.4"/>
          </svg>
      );
}

  /*let svgView = (
    <svg
      height={`${h}pt`}
      viewBox="0 0 512 512"
      width={`${w}pt`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m512 256c0 141.386719-114.613281 256-256 256s-256-114.613281-256-256 114.613281-256 256-256 256 114.613281 256 256zm0 0"
        fill={ac}
      />
    </svg>
  );*/
  return (
    "data:image/svg+xml," + escape(ReactDOMServer.renderToStaticMarkup(svgView))
  );
};
const getDiffInMin = data => {
  let diff = 5;
  try {
    diff = moment().diff(moment.unix(data[0]["createdAt"]), "minutes");
  } catch (e) {}
  return diff;
};
