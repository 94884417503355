import React from "react";
import ReactDOM from "react-dom";
import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import FirebaseContextApp from "./FirebaseContextApp";
import ChartComponent from "./Charts";


const routing = (
  <Router>
    <div>
      <Route
      exact path="/"
        component={FirebaseContextApp}
      />
      <Route path="/charts" component={ChartComponent} />
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));
