import React, { Component } from "react";
import "../index.css";
import * as serviceWorker from "../serviceWorker";
import App from "../components/App";
import Firebase, { FirebaseContext } from "../components/Firebase";

class FirebaseContextApp extends Component {
  state = {};
  render() {
    return (
      <FirebaseContext.Provider>
        <App />
      </FirebaseContext.Provider>
    );
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default FirebaseContextApp;
