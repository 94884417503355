import React from "react";
import ReactDOMServer from "react-dom/server";
const moment = require("moment");

export const PM_DETAILS = [
  { c: "#8AB8FF", ac: "#8AB8FFAF", r: [0, 50] },
  { c: "#FFEE52", ac: "#FFEE52AF", r: [50, 100] },
  { c: "#F2CC0C", ac: "#F2CC0CAF", r: [100, 150] },
  { c: "#FF780A", ac: "#FF780AAF", r: [150, 200] },
  { c: "#E02F44", ac: "#E02F44AF", r: [200, 250] },
  { c: "#C4162A", ac: "#C4162AAF", r: [250, 300] }
];
export const IIT_D_LL = [28.545, 77.1926];
const isInBetween = (arr, value) => {
  if (value > arr[0] && value <= arr[1]) return true;
  return false;
};

const MAX_RAD = 100;
export const getMarkerDetails = value => {
  if (typeof value === "string") value = parseInt(value);
  // default value
  let pmLevel = PM_DETAILS[0];
  for (let val of PM_DETAILS) {
    if (isInBetween(val.r, value)) {
      pmLevel = val;
      break;
    }
  }
  // what to do if value is less than 0 and greater than 300
  // if(value < 0 ) pmLevel =PM_DETAILS[0];
  // if value is more than 300
  let lastPmDetail = PM_DETAILS[PM_DETAILS.length - 1];
  if (value > lastPmDetail.r[1]) {
    pmLevel = lastPmDetail;
    //value = lastPmDetail.r[1];
  }
  let r = (value / pmLevel.r[1]) * MAX_RAD;
  if (r <= 0) r = 10;
  return { w: r, h: r, ac: pmLevel.ac, c: pmLevel.c };
};

export const formatAQIData = data => {
  let result = [];
  try {
    for (let d of data) {
      let obj = {};
      if (!d.location) {
        continue;
      }
      obj.loc_name = d.location;
      if (!d.coordinates) {
        continue;
      }
      if (!d.coordinates.latitude || !d.coordinates.longitude) {
        continue;
      }
      obj.lat = d.coordinates.latitude;
      obj.lng = d.coordinates.longitude;
      let foundPm = false;
      for (let value of d.measurements) {
        if (value.parameter === "pm25") {
          obj.pmLevel = Math.floor(value.value);
          obj.unit = value.unit;
          foundPm = true;
          break;
        }
      }
      if (!foundPm) {
        obj.pmLevel = 0;
        obj.unit = "µg/m³";
      }
      result.push(obj);
    }
  } catch (e) {
    return result;
  }
  return result;
};

export const getDateDiffInDays = past => {
  let today = moment(); //.format('YYYY-MM-DD');
  let pastDate = moment(past);
  let dayDiff = today.diff(pastDate, "days");
  return dayDiff < 2;
};

export const ClusterIcon = (pmLevel = 0) => {
  let { w, h, ac, c } = getMarkerDetails(pmLevel);
  w = 60;
  h = 60;
  let svgView = getSvg(h, w, ac, c);
  return (
    "data:image/svg+xml," + escape(ReactDOMServer.renderToStaticMarkup(svgView))
  );
};

export const getSvg = (h, w, ac, c) => (
  <svg
    height={`${h}pt`}
    viewBox="0 0 512 512"
    width={`${w}pt`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="250" cy="250" r="220" fill={ac} stroke={ac} strokeWidth="8" />
    <circle
      cx="250"
      cy="250"
      r="150"
      fill={ac}
      stroke={"white"}
      strokeWidth="8"
    />
  </svg>
);

export const getHourRangesBetweenIntervals = (startSecond, endSecond) => {
  let hourSecs = 3600;
  let range = [];
  if (endSecond - startSecond < hourSecs) {
    // sending a range worth of an hour only
    range.push([startSecond, startSecond + hourSecs]);
    return range;
  }
  let start = startSecond;
  let nextVal = startSecond + hourSecs;
  while (true) {
    if (nextVal > endSecond) {
      break;
    }
    range.push([start, nextVal]);
    start = nextVal;
    nextVal = start + hourSecs;
  }
  return range;
};

export const getDateFromEpoch = (epoch, format = "DD/MM/YYYY") => {
  if (!epoch) return moment().format(format);

  try {
    return moment(epoch).format(format);
  } catch (e) {
    return moment().format(format);
  }
};

export const getColorViaPMValue = (pmValue = 0) => {
  let pmLevel = PM_DETAILS[0];
  for (let val of PM_DETAILS) {
    if (isInBetween(val.r, pmValue)) {
      pmLevel = val;
      break;
    }
  }
  return pmLevel.c;
};

let data = [
  {
    "": 0,
    TimeStamp: "2019-04-01 15:47:46:979",
    Latitude: 28.543869,
    Longitude: 77.191101,
    "PM2.5": 136
  },
  {
    "": 1,
    TimeStamp: "2019-04-01 15:47:46:979",
    Latitude: 28.543876,
    Longitude: 77.191116,
    "PM2.5": 57
  },
  {
    "": 2,
    TimeStamp: "2019-04-01 15:48:35:329",
    Latitude: 28.543886,
    Longitude: 77.191108,
    "PM2.5": 98
  },
  {
    "": 3,
    TimeStamp: "2019-04-01 15:48:37:070",
    Latitude: 28.543899,
    Longitude: 77.191085,
    "PM2.5": 178
  },
  {
    "": 4,
    TimeStamp: "2019-04-01 15:48:38:842",
    Latitude: 28.543899,
    Longitude: 77.191085,
    "PM2.5": 44
  },
  {
    "": 5,
    TimeStamp: "2019-04-01 15:48:42:357",
    Latitude: 28.543849,
    Longitude: 77.191123,
    "PM2.5": 113
  },
  {
    "": 6,
    TimeStamp: "2019-04-01 15:48:47:501",
    Latitude: 28.543832,
    Longitude: 77.191131,
    "PM2.5": 223
  },
  {
    "": 7,
    TimeStamp: "2019-04-01 15:48:52:649",
    Latitude: 28.543832,
    Longitude: 77.191131,
    "PM2.5": 104
  },
  {
    "": 8,
    TimeStamp: "2019-04-01 15:48:57:801",
    Latitude: 28.543804,
    Longitude: 77.191162,
    "PM2.5": 142
  },
  {
    "": 9,
    TimeStamp: "2019-04-01 15:49:03:126",
    Latitude: 28.543807,
    Longitude: 77.191169,
    "PM2.5": 80
  },
  {
    "": 10,
    TimeStamp: "2019-04-01 15:49:07:666",
    Latitude: 28.543834,
    Longitude: 77.191162,
    "PM2.5": 109
  },
  {
    "": 11,
    TimeStamp: "2019-04-01 15:49:15:192",
    Latitude: 28.543842,
    Longitude: 77.191146,
    "PM2.5": 45
  },
  {
    "": 12,
    TimeStamp: "2019-04-01 15:49:18:187",
    Latitude: 28.543849,
    Longitude: 77.191169,
    "PM2.5": 285
  },
  {
    "": 13,
    TimeStamp: "2019-04-01 15:49:22:500",
    Latitude: 28.543855,
    Longitude: 77.191177,
    "PM2.5": 245
  },
  {
    "": 14,
    TimeStamp: "2019-04-01 15:49:27:646",
    Latitude: 28.543857,
    Longitude: 77.191177,
    "PM2.5": 120
  },
  {
    "": 15,
    TimeStamp: "2019-04-01 15:49:32:800",
    Latitude: 28.543874,
    Longitude: 77.191177,
    "PM2.5": 217
  },
  {
    "": 16,
    TimeStamp: "2019-04-01 15:49:37:952",
    Latitude: 28.543897,
    Longitude: 77.191162,
    "PM2.5": 291
  },
  {
    "": 17,
    TimeStamp: "2019-04-01 15:49:43:103",
    Latitude: 28.543901,
    Longitude: 77.191162,
    "PM2.5": 232
  },
  {
    "": 18,
    TimeStamp: "2019-04-01 15:49:48:255",
    Latitude: 28.543897,
    Longitude: 77.191162,
    "PM2.5": 90
  },
  {
    "": 19,
    TimeStamp: "2019-04-01 15:49:53:403",
    Latitude: 28.543901,
    Longitude: 77.191162,
    "PM2.5": 60
  },
  {
    "": 20,
    TimeStamp: "2019-04-01 15:49:58:553",
    Latitude: 28.543966,
    Longitude: 77.191169,
    "PM2.5": 140
  },
  {
    "": 21,
    TimeStamp: "2019-04-01 15:50:02:794",
    Latitude: 28.544036,
    Longitude: 77.191162,
    "PM2.5": 71
  },
  {
    "": 22,
    TimeStamp: "2019-04-01 15:50:07:943",
    Latitude: 28.544132,
    Longitude: 77.191131,
    "PM2.5": 73
  },
  {
    "": 23,
    TimeStamp: "2019-04-01 15:50:13:090",
    Latitude: 28.544204,
    Longitude: 77.191169,
    "PM2.5": 151
  },
  {
    "": 24,
    TimeStamp: "2019-04-01 15:50:18:235",
    Latitude: 28.544132,
    Longitude: 77.191322,
    "PM2.5": 99
  },
  {
    "": 25,
    TimeStamp: "2019-04-01 15:50:23:383",
    Latitude: 28.544073,
    Longitude: 77.191505,
    "PM2.5": 162
  },
  {
    "": 26,
    TimeStamp: "2019-04-01 15:50:28:529",
    Latitude: 28.544054,
    Longitude: 77.191764,
    "PM2.5": 225
  },
  {
    "": 27,
    TimeStamp: "2019-04-01 15:50:33:676",
    Latitude: 28.544124,
    Longitude: 77.191993,
    "PM2.5": 39
  },
  {
    "": 28,
    TimeStamp: "2019-04-01 15:50:38:799",
    Latitude: 28.544208,
    Longitude: 77.19223,
    "PM2.5": 1
  },
  {
    "": 29,
    TimeStamp: "2019-04-01 15:50:43:095",
    Latitude: 28.544298,
    Longitude: 77.192382,
    "PM2.5": 31
  },
  {
    "": 30,
    TimeStamp: "2019-04-01 15:50:48:226",
    Latitude: 28.544384,
    Longitude: 77.192642,
    "PM2.5": 168
  },
  {
    "": 31,
    TimeStamp: "2019-04-01 15:50:53:362",
    Latitude: 28.54449,
    Longitude: 77.192871,
    "PM2.5": 150
  },
  {
    "": 32,
    TimeStamp: "2019-04-01 15:50:58:498",
    Latitude: 28.544563,
    Longitude: 77.193069,
    "PM2.5": 288
  },
  {
    "": 33,
    TimeStamp: "2019-04-01 15:51:03:638",
    Latitude: 28.544626,
    Longitude: 77.193267,
    "PM2.5": 80
  },
  {
    "": 34,
    TimeStamp: "2019-04-01 15:51:08:778",
    Latitude: 28.544719,
    Longitude: 77.193572,
    "PM2.5": 190
  },
  {
    "": 35,
    TimeStamp: "2019-04-01 15:51:13:918",
    Latitude: 28.544857,
    Longitude: 77.19384,
    "PM2.5": 127
  },
  {
    "": 36,
    TimeStamp: "2019-04-01 15:51:19:062",
    Latitude: 28.544967,
    Longitude: 77.194145,
    "PM2.5": 205
  },
  {
    "": 37,
    TimeStamp: "2019-04-01 15:51:23:370",
    Latitude: 28.545055,
    Longitude: 77.194458,
    "PM2.5": 16
  },
  {
    "": 38,
    TimeStamp: "2019-04-01 15:51:28:513",
    Latitude: 28.545181,
    Longitude: 77.194839,
    "PM2.5": 223
  },
  {
    "": 39,
    TimeStamp: "2019-04-01 15:51:33:655",
    Latitude: 28.545305,
    Longitude: 77.195251,
    "PM2.5": 212
  },
  {
    "": 40,
    TimeStamp: "2019-04-01 15:51:38:799",
    Latitude: 28.545381,
    Longitude: 77.195533,
    "PM2.5": 103
  },
  {
    "": 41,
    TimeStamp: "2019-04-01 15:51:43:942",
    Latitude: 28.545392,
    Longitude: 77.195671,
    "PM2.5": 86
  },
  {
    "": 42,
    TimeStamp: "2019-04-01 15:51:49:227",
    Latitude: 28.545425,
    Longitude: 77.19577,
    "PM2.5": 134
  },
  {
    "": 43,
    TimeStamp: "2019-04-01 15:51:53:749",
    Latitude: 28.545455,
    Longitude: 77.195861,
    "PM2.5": 207
  },
  {
    "": 44,
    TimeStamp: "2019-04-01 15:51:59:035",
    Latitude: 28.545551,
    Longitude: 77.19606,
    "PM2.5": 291
  },
  {
    "": 45,
    TimeStamp: "2019-04-01 15:52:04:569",
    Latitude: 28.545644,
    Longitude: 77.196304,
    "PM2.5": 205
  },
  {
    "": 46,
    TimeStamp: "2019-04-01 15:52:09:322",
    Latitude: 28.54577,
    Longitude: 77.19638,
    "PM2.5": 28
  },
  {
    "": 47,
    TimeStamp: "2019-04-01 15:52:13:631",
    Latitude: 28.545831,
    Longitude: 77.196403,
    "PM2.5": 144
  },
  {
    "": 48,
    TimeStamp: "2019-04-01 15:52:18:777",
    Latitude: 28.545843,
    Longitude: 77.196426,
    "PM2.5": 77
  },
  {
    "": 49,
    TimeStamp: "2019-04-01 15:52:23:920",
    Latitude: 28.54586,
    Longitude: 77.196441,
    "PM2.5": 177
  },
  {
    "": 50,
    TimeStamp: "2019-04-01 15:52:29:063",
    Latitude: 28.545913,
    Longitude: 77.196395,
    "PM2.5": 3
  },
  {
    "": 51,
    TimeStamp: "2019-04-01 15:52:34:207",
    Latitude: 28.54601,
    Longitude: 77.196022,
    "PM2.5": 147
  },
  {
    "": 52,
    TimeStamp: "2019-04-01 15:52:39:351",
    Latitude: 28.546136,
    Longitude: 77.19564,
    "PM2.5": 260
  }
];

export const getMarkers = () => {
  let markers = [];
  for (var i = 0; i < data.length; i++) {
    var magnitude = data[i]["PM2.5"];
    var weightedLoc = {
      lat: data[i].Latitude,
      lng: data[i].Longitude,
      pm2_5: magnitude
    };
    markers.push(weightedLoc);
  }
  return markers;
};

export const HEAT_MAP_VIEW = "heatMapView";
export const POLYLINE_MAP_VIEW = "polyLineMapView";
export const MARKERS_MAP_VIEW = "markersMapView";

export const PM_2_5_KEY = "pm2_5";
export const LAT_KEY = "lat";
export const LNG_KEY = "lng";

export const tempData = [
  {
    dateTime: "2020-09-03T13:02:31",
    uid: "024a9e81-8bac-4c7b-bc46-b62c2a840218",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543633",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T13:02:22",
    uid: "7eefb1db-95f0-48d3-82ce-15b0df0dcba6",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543665",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T13:02:13",
    uid: "b121948a-d533-4af5-a13b-1b8beada6b61",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543669",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T13:02:10",
    uid: "19adda08-0539-452d-aeca-32477d5d2944",
    deviceId: "00000000f9f22187",
    pm2_5: "47",
    latitide: "28.543669",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T13:02:04",
    uid: "875928c2-d898-4467-b40b-5a4c01034ec7",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543665",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T13:01:58",
    uid: "1cbd5345-c82c-4168-b788-66abca1dfc56",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543661",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T13:01:55",
    uid: "d5bf8a3d-4a55-4b4a-a4ae-ce29a0749def",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543665",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T13:01:49",
    uid: "0c81aa06-af3e-454d-82fc-29d30ae13218",
    deviceId: "00000000f9f22187",
    pm2_5: "47",
    latitide: "28.543665",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T13:01:46",
    uid: "3e4c1c6d-50d8-42d8-858f-acacaa897ab0",
    deviceId: "00000000f9f22187",
    pm2_5: "47",
    latitide: "28.543665",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T13:01:37",
    uid: "4d6de389-bd83-4a50-8c06-43318ec2a9ab",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543665",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T13:01:34",
    uid: "61f81e1a-b543-4272-901c-f65ec707b1eb",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543665",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T13:01:28",
    uid: "b44fe203-821e-47f9-81d0-ebd86d548338",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543665",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T13:01:22",
    uid: "d38137f8-fe68-425d-855b-38a2c4a779ab",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543673",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T13:01:16",
    uid: "a5666191-96bb-49ad-9e89-aeb4acbb4dc1",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543676",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T13:01:04",
    uid: "9d8bfa65-9875-453a-8e86-989d3fa0ee4e",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.54369",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T13:01:01",
    uid: "92122d55-040d-4a29-a074-a2f73529eec9",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.54369",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T13:00:58",
    uid: "8c0cf64b-2a81-4944-b2e4-668577187caa",
    deviceId: "00000000f9f22187",
    pm2_5: "42",
    latitide: "28.543694",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T13:00:55",
    uid: "8a4c6206-f714-47a6-b860-635aae4f4459",
    deviceId: "00000000f9f22187",
    pm2_5: "42",
    latitide: "28.543694",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T13:00:52",
    uid: "fa9cf8e5-8d2b-45f2-906e-dac1f9cd392c",
    deviceId: "00000000f9f22187",
    pm2_5: "42",
    latitide: "28.543697",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T13:00:49",
    uid: "90a47c56-d5e1-489f-9a27-9afd265d655e",
    deviceId: "00000000f9f22187",
    pm2_5: "42",
    latitide: "28.543697",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T13:00:46",
    uid: "777749d5-5820-424f-86d5-4115faf547f0",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.543697",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T13:00:40",
    uid: "8cb5ea21-cafd-474f-9ef4-e8fbb8ce17f3",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.54369",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T13:00:34",
    uid: "bce21d1f-68da-4dea-bf0a-4f6b02a40fab",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.54368",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T13:00:31",
    uid: "7b0ecaed-77fc-4935-acc7-6ab300b899fc",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543676",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T13:00:28",
    uid: "35645cee-7c32-4696-9041-8273fe8da124",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.54368",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T13:00:22",
    uid: "4d6fa982-5927-4ec5-9fb3-e6931a9cf3e9",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543676",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T13:00:16",
    uid: "8f7d7d59-0934-40da-ab8a-e15bf279c822",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.54368",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T13:00:13",
    uid: "e4a51f4f-e3c4-402a-ace4-9c6ef3a0ecb0",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.54368",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T13:00:01",
    uid: "daa1754b-08c1-4f2f-81ab-93fdc607edaf",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543684",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T12:59:58",
    uid: "b8a548be-a960-448c-9abf-018727ac3336",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543694",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T12:59:52",
    uid: "cc88ec9c-688d-4a53-8d5b-11889cfd7109",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.54369",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T12:59:49",
    uid: "f1125854-c009-48d3-85c3-60d1744969cc",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543694",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T12:59:46",
    uid: "f61694eb-6afa-4630-9dcf-56549ca2f031",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.54369",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T12:59:43",
    uid: "77100ea9-c4c6-41bb-8150-3c097cf284f6",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.54369",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:59:37",
    uid: "befea7b5-52ee-4cde-8fa2-0763c8fb3e21",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.54368",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:59:31",
    uid: "bca568ed-cc90-41d7-a9cb-74bb076fa5ab",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.54368",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:59:25",
    uid: "2053bbdc-c3c3-454f-956e-053ef32d872b",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543684",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:59:22",
    uid: "72c3d362-23e5-465e-8ffc-9bb4585ca8d7",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.54369",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:59:16",
    uid: "53fc00a0-5f22-4bda-9469-498a6624abec",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.54368",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:59:10",
    uid: "2a58be9e-0aef-44a6-905d-834596f2bf39",
    deviceId: "00000000f9f22187",
    pm2_5: "41",
    latitide: "28.54368",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:59:07",
    uid: "689c936e-3c5d-4144-b2c6-68ba66d464bd",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.543676",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:59:01",
    uid: "9e29debc-70ea-4056-b565-e8fc1b2b350d",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543661",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:58:58",
    uid: "ccd5a40c-6321-447b-ab7d-a7f2925c05f7",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543661",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:58:52",
    uid: "a541e5e1-d1fc-4279-928c-e4558085b2c0",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543665",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:58:49",
    uid: "71e24fe4-253e-4257-9ae0-fbe1b5986a98",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543665",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:58:46",
    uid: "9f2fc0ba-3f3a-4218-8c84-c3fce873f031",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543665",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:58:43",
    uid: "725193d9-7702-44da-bca1-5ca644387a2c",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543665",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:58:40",
    uid: "30523c6a-976f-4c6e-bb82-e29e905a098d",
    deviceId: "00000000f9f22187",
    pm2_5: "47",
    latitide: "28.543669",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:58:37",
    uid: "09f6a8f1-0af1-4e51-8f80-8cf7ab95ccfb",
    deviceId: "00000000f9f22187",
    pm2_5: "47",
    latitide: "28.543669",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:58:34",
    uid: "48694237-8ebe-4285-a6d6-a333c110013b",
    deviceId: "00000000f9f22187",
    pm2_5: "47",
    latitide: "28.543669",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:58:31",
    uid: "4ab79d8d-4814-44b8-b240-b1b47ddce647",
    deviceId: "00000000f9f22187",
    pm2_5: "47",
    latitide: "28.543673",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T12:58:25",
    uid: "30f1f37c-3a99-4697-9fc2-74e9a53e93b0",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543676",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T12:58:22",
    uid: "b835bd6e-df0c-4b8e-8b18-1011373af09b",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.54368",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T12:58:16",
    uid: "0da0e367-5166-4e6d-8fb9-f5481b16e5e0",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.54369",
    long: "77.19104"
  },
  {
    dateTime: "2020-09-03T12:58:10",
    uid: "fcade242-1bd7-4249-a0f3-05af2d88580a",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.54369",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:58:07",
    uid: "0f0db944-da4f-41a6-a70a-01102605f864",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.54369",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:58:04",
    uid: "0df08e44-f96a-4016-9425-43cf7dd48863",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543694",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:58:01",
    uid: "abfdd170-a6f9-4ee1-a9b2-a713f7c0011b",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543697",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:57:55",
    uid: "d679de3f-152e-4f32-8a44-23f5dab53959",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543701",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:57:52",
    uid: "599fb661-277e-4a87-b232-93df3475dc77",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543701",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:57:49",
    uid: "6426e410-0d22-45bd-b1cf-bacfde858bed",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.543697",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:57:46",
    uid: "6472e09e-7cc9-4670-a5ec-2b59f5365480",
    deviceId: "00000000f9f22187",
    pm2_5: "42",
    latitide: "28.543701",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:57:22",
    uid: "a8f3f11f-ddc5-4e76-8fc0-6ef71961f6ce",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543697",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T12:57:19",
    uid: "f71b856e-7d32-43e5-ab1b-02fd6c517a03",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543684",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T12:57:10",
    uid: "4ae7064c-7f7a-48f9-9324-60f360f243f7",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.54369",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T12:57:07",
    uid: "5e93c3f3-beff-429f-80e0-f4ae145d3c62",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.54368",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T12:57:04",
    uid: "5732395f-a839-4472-8557-ad127d01adeb",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.54368",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T12:56:58",
    uid: "f6c28d72-cff9-4842-8573-0e501d3ab694",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543684",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T12:56:55",
    uid: "2a2e512b-2c03-48eb-840b-b66928500d1b",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.54369",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T12:56:49",
    uid: "995fad05-57fb-4e0c-8a4c-94894d578335",
    deviceId: "00000000f9f22187",
    pm2_5: "42",
    latitide: "28.543701",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:56:43",
    uid: "b1d88b55-7c7e-448b-b981-ca0251b29e64",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543709",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:56:37",
    uid: "ba2e83eb-16e3-4ee4-bf7e-2e95cb95a484",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543718",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:56:25",
    uid: "c3c251f5-eac1-45b1-a00a-e4c335aaca18",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543697",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:56:19",
    uid: "af6fc572-4ec1-46bd-b7dc-cdfaf230e419",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543697",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:56:16",
    uid: "094f098f-0f4d-4dcb-9af5-c7f6b2db6e50",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543701",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T12:56:13",
    uid: "96c643f4-c977-41bf-a821-e5c64fbc050b",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543705",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:56:10",
    uid: "bfeea8b2-c70a-4a67-8765-ff7485460884",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543709",
    long: "77.191032"
  },
  {
    dateTime: "2020-09-03T12:56:04",
    uid: "61be07ff-94bd-462c-aaac-741f619e4de2",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543701",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T12:55:46",
    uid: "67a6dcdc-4fd4-44a0-8f6d-6f345b47a592",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543726",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T12:55:22",
    uid: "77f09931-8eef-4105-8db1-472ee771b425",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.54373",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T12:55:19",
    uid: "58f4d88a-798b-4a4f-ab2c-82b223491af2",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.54373",
    long: "77.191025"
  },
  {
    dateTime: "2020-09-03T12:55:10",
    uid: "b39fcac6-25ac-4567-809d-2eec3d940bd9",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.543734",
    long: "77.191017"
  },
  {
    dateTime: "2020-09-03T12:55:07",
    uid: "ed88583e-c993-4bb5-be29-81d68c7a7e1d",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.54373",
    long: "77.191017"
  },
  {
    dateTime: "2020-09-03T12:55:01",
    uid: "9bea0f57-90c7-4c2b-8ce6-56a859c5e4f3",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543726",
    long: "77.191017"
  },
  {
    dateTime: "2020-09-03T12:54:55",
    uid: "f84bf69d-7fab-4483-a746-a9b1d98ea3c0",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543718",
    long: "77.19101"
  },
  {
    dateTime: "2020-09-03T12:54:49",
    uid: "cd47602d-c9ee-44b6-8ed0-72fdcd07502d",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543709",
    long: "77.19101"
  },
  {
    dateTime: "2020-09-03T12:54:43",
    uid: "79d98186-e075-4553-bd40-014604b65687",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.543705",
    long: "77.19101"
  },
  {
    dateTime: "2020-09-03T12:54:40",
    uid: "7939f1bd-9429-414d-9c35-42d2225a3e77",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543705",
    long: "77.19101"
  },
  {
    dateTime: "2020-09-03T12:54:37",
    uid: "861eda32-16b7-485d-9cd1-7a449a72c1c9",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543709",
    long: "77.191002"
  },
  {
    dateTime: "2020-09-03T12:54:34",
    uid: "189a67e2-394f-469b-9834-80acadeebed7",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543705",
    long: "77.191002"
  },
  {
    dateTime: "2020-09-03T12:54:28",
    uid: "f2e86fa8-5b73-4472-9b42-70af58b1dab7",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.543705",
    long: "77.190994"
  },
  {
    dateTime: "2020-09-03T12:54:25",
    uid: "fb22a170-648d-41b6-8f69-4ab7cb7b5d50",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543705",
    long: "77.190994"
  },
  {
    dateTime: "2020-09-03T12:54:22",
    uid: "cf66ab15-5db8-46df-bdf8-94798ec2bc68",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543713",
    long: "77.190994"
  },
  {
    dateTime: "2020-09-03T12:54:19",
    uid: "e384da35-cb8c-461f-998d-99eeffffc059",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543709",
    long: "77.190994"
  },
  {
    dateTime: "2020-09-03T12:54:16",
    uid: "c3defa17-a36d-4b14-825e-c740b934c526",
    deviceId: "00000000f9f22187",
    pm2_5: "43",
    latitide: "28.543713",
    long: "77.190994"
  },
  {
    dateTime: "2020-09-03T12:54:13",
    uid: "5faa1a58-0ca0-4a2f-ac7e-3df6bd8a37c3",
    deviceId: "00000000f9f22187",
    pm2_5: "44",
    latitide: "28.543705",
    long: "77.190994"
  },
  {
    dateTime: "2020-09-03T12:54:07",
    uid: "4ef125f4-6a1c-4933-a556-6af7d6d77987",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543705",
    long: "77.190994"
  },
  {
    dateTime: "2020-09-03T12:54:01",
    uid: "4cc3ab0d-5cfc-46d0-85aa-437afb9477e9",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543713",
    long: "77.190994"
  },
  {
    dateTime: "2020-09-03T12:53:55",
    uid: "801daa20-2c2b-4cdc-a6a7-0645ba6c8829",
    deviceId: "00000000f9f22187",
    pm2_5: "46",
    latitide: "28.543718",
    long: "77.190994"
  },
  {
    dateTime: "2020-09-03T12:53:49",
    uid: "04368820-a5ed-4daf-87ea-afb6a3ea85ab",
    deviceId: "00000000f9f22187",
    pm2_5: "45",
    latitide: "28.543726",
    long: "77.190994"
  }
];
