import React from "react";
import "./style.css";
import { PM_DETAILS } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export default class EmptyDataNotification extends React.Component {
  render = () => {
    return (
      <div
        className=" container "
        style={{
          position: "absolute",
          bottom: "180px",
          left: "20px",
          padding: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          style={{
            color: "#000000",
            cursor: "pointer",
            fontSize: "20px",
            border: 0,
            paddingRight: "10px"
          }}
        />
        <br />
        <div style={{ paddingTop: "4px" }}>{"Empty Data"}</div>
      </div>
    );
  };
}
